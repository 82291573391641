import React, { useEffect, useState } from 'react';
import { Card, Table, message } from 'antd';
import axios from 'axios';
import moment from 'moment';

const AttendanceHistory = () => {
  const [userId, setUserId] = useState('');
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const id = sessionStorage.getItem('userId');
    setUserId(id);
  }, []);

  useEffect(() => {
    if (userId) {
      fetchAttendanceData(userId);
    }
  }, [userId]);

  const fetchAttendanceData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.post('https://testing.vaptix.com/getEmpAttendanceById.php', {
        employeeId: id,
      });
      console.log('API Response:', response);
      const { data } = response;
      if (data.status === 'success') {
        setAttendanceData(data.data);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      message.error('Failed to fetch attendance data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => moment(text).format('YYYY-MM-DD'),
    },
    {
      title: 'Login Time',
      dataIndex: 'login_time',
      key: 'login_time',
      render: (text) => moment(text, 'HH:mm:ss').format('hh:mm A'),
    },
    {
      title: 'Logout Time',
      dataIndex: 'logout_time',
      key: 'logout_time',
      render: (text) => moment(text, 'HH:mm:ss').format('hh:mm A'),
    },
    {
      title: 'Duration',
      key: 'duration',
      render: (record) => {
        const duration = `${record.durationH}h ${record.durationM}m ${record.durationS}s`;
        return duration;
      },
    },
  ];

  return (
    <Card title="Attendance History" style={{ width: '100%' }}>
      <Table
        columns={columns}
        dataSource={attendanceData}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </Card>
  );
};

export default AttendanceHistory;
