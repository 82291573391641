import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Card, Row, Col, Form, Input, Button, message } from 'antd';
import '../styles/CommonStyle.css'; // Import the CSS file
import loginBg from '../assets/loginbg.jpg'; // Import the background image

const { Content } = Layout;

const Login = ({ setAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    try {
      // Simulate authentication process
      // Replace with actual authentication logic (e.g., API call)
      if (username === 'admin' && password === 'admin123') {
        sessionStorage.setItem('authenticated', 'true');
        setAuthenticated(true);
        navigate('/AdminDashboard');
        setShowSuccessMessage(true);
      } else {
        // Simulate employee login check
        const response = await fetch('https://testing.vaptix.com/view_users.php');
        if (!response.ok) {
          message.error('Your account is inactive. Please contact administrator.');
        }
        const { data } = await response.json();
        
        // Find user by username and password
        const user = data.find((user) => user.employee_id === username && user.password === password);
        
        if (user) {
          // Check if user status is not "inactive"
          if (user.status === 'inactive') {
            message.error('Your account is inactive. Please contact administrator.');
          } else {
            sessionStorage.setItem('authenticated', 'true');
            sessionStorage.setItem('userId', user.employee_id);
            sessionStorage.setItem('userFirstName', `${user.firstname} ${user.lastname}`);
            setAuthenticated(true);            
            message.success('Sucessfully Login: '+ sessionStorage.getItem('userFirstName'));
            navigate('/EmployeeDashboard');
          }
        } else {
          message.error('Invalid credentials');
        }
      }
    } catch (error) {
      console.error('Error logging in:', error);
      message.error('Failed to log in. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout className="full-height-layout" style={{ backgroundImage: `url(${loginBg})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '60vh' }}>
      <Content className="centered-content">
        <Row justify="center">
          <Col>
            <Card hoverable style={{ width: '420px' }}>
              <h1 className='LoginPage_Title'>Login</h1>
              <Form
                name="login"
                initialValues={{ remember: true }}
                onFinish={handleLogin}
              >
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Input
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
      {showSuccessMessage && (
        <div style={{ position: 'fixed', bottom: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 9999 }}>
          <message.success duration={2} onClose={() => setShowSuccessMessage(false)}>Successfully logged in!</message.success>
        </div>
      )}
    </Layout>
  );
};

export default Login;
