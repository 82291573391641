// MyPerformance.js
import React from 'react';
import { Card } from 'antd';

const MyPerformance = () => (
  <Card title="My Performance">
    <p>Performance data will be displayed here.</p>
  </Card>
);

export default MyPerformance;
