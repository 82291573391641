import React, { useState } from 'react';
import { Layout, Menu, Button } from 'antd';
import { UserOutlined, ScheduleOutlined, ProfileOutlined } from '@ant-design/icons';
import Admindashboard from '../components/Admin/EmployeeList';
import Audit from '../components/Admin/AuditZone';
import Report from '../components/Admin/Report';

const { Header, Sider, Content } = Layout;

const AdminDashboard = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard');

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'loghours':
        return <Audit />;
      case 'report':
        return <Report />;
      default:
        return <Admindashboard />;
    }
  };

  const handleLogout = () => {
    // You can perform any logout logic here if needed
    // For example, clear user session or token
    window.location.reload(); // Refresh the page
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="site-layout-background" style={{ padding: '0 16px', background: '#fff' }}>
        <Button type="primary" onClick={handleLogout} style={{ float: 'right' }}>
          Logout
        </Button>
      </Header>
      <Layout>
        <Sider width={240} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={['dashboard']}
            style={{ height: '100%', borderRight: 0 }}
            onClick={(e) => setSelectedMenuItem(e.key)}
          >
            <Menu.Item key="dashboard" icon={<UserOutlined />}>
              Admin Dashboard
            </Menu.Item>
            <Menu.Item key="loghours" icon={<ScheduleOutlined />}>
              Attendance Sheet
            </Menu.Item>
            <Menu.Item key="report" icon={<ScheduleOutlined />}>
              Report
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {renderContent()}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AdminDashboard;
