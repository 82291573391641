// Clock.js
import React, { useState, useEffect } from 'react';
import moment from 'moment';

const Clock = ({ startTime }) => {
  const [elapsedTime, setElapsedTime] = useState('00:00:00');

  useEffect(() => {
    if (!startTime) return;

    const interval = setInterval(() => {
      const now = moment();
      const start = moment(startTime, 'HH:mm:ss');
      const duration = moment.duration(now.diff(start));

      const hours = String(duration.hours()).padStart(2, '0');
      const minutes = String(duration.minutes()).padStart(2, '0');
      const seconds = String(duration.seconds()).padStart(2, '0');
      setElapsedTime(`${hours}:${minutes}:${seconds}`);
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <div>
      <p>Elapsed Time: {elapsedTime}</p>
    </div>
  );
};

export default Clock;
