import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from 'antd';
import ProtectedRoute from './components/Admin/ProtectedRoute';
import Login from './pages/Login';
import AdminDashboard from './pages/AdminDashboard';
import EmployeeDashboard from './pages/EmployeeDashboard';

const { Header, Content, Sider } = Layout;

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const isAuthenticated = sessionStorage.getItem('authenticated');
    if (isAuthenticated === 'true') {
      setAuthenticated(true);
    }
  }, []);

  const handleUserLogout = () => {
    sessionStorage.removeItem('authenticated');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('userFirstName');
    setAuthenticated(false);
  };

  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Routes>
          <Route path="/" element={<Login setAuthenticated={setAuthenticated} />} />
          <Route
            path="/admindashboard"
            element={authenticated ? <AdminDashboard /> : <Navigate to="/" />}
          />
          <Route
            path="/EmployeeDashboard"
            element={authenticated ? <EmployeeDashboard /> : <Navigate to="/" />}
          />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
