import React, { useState, useEffect } from 'react';
import { Table, Space, Input, Button, Spin, message } from 'antd';
import axios from 'axios';
import moment from 'moment';

const { Search } = Input;

const EmployeeAttendanceReport = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [employeeId, setEmployeeId] = useState('');

  // Function to fetch attendance data by employee ID
  const fetchAttendanceById = async () => {
    try {
      setLoading(true);
      const response = await axios.post('https://testing.vaptix.com/getEmpAttendanceById.php', {
        employeeId: employeeId,
      });
      
      if (response.data.status === 'success') {
        let sortedData = response.data.data.sort((a, b) => moment(b.date).diff(moment(a.date))); // Sorting in descending order
        setAttendanceData(sortedData);
        setError('');
        if (sortedData.length === 0) {
          throw new Error(`No records found for employeeId: ${employeeId}`);
        }
      } else {
        throw new Error(response.data.message || 'Failed to fetch employee attendance data');
      }
    } catch (error) {
      setError(error.message || 'Failed to fetch employee attendance data. Please try again.');
      setAttendanceData([]);
    } finally {
      setLoading(false);
    }
  };

  // Handler for search button click
  const handleSearch = () => {
    if (employeeId.trim() === '') {
      message.error('Please enter Employee ID');
      return;
    }
    fetchAttendanceById();
  };

  // Table columns configuration
  const columns = [
    { title: 'Date', dataIndex: 'date', key: 'date', sorter: true },
    { title: 'Login Time', dataIndex: 'login_time', key: 'login_time' },
    { title: 'Logout Time', dataIndex: 'logout_time', key: 'logout_time' },
    {
      title: 'Duration',
      render: (text, record) => (
        <span>{`${record.durationH}:${record.durationM}:${record.durationS}`}</span>
      ),
      key: 'duration',
      sorter: true,
    },
  ];

  return (
    <div>
      <h2>Employee Attendance Report</h2>
      <Space style={{ marginBottom: 16 }}>
        <Input placeholder="Enter Employee ID" value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} style={{ width: 200 }} />
        <Button type="primary" onClick={handleSearch}>Search</Button>
      </Space>
      <Table columns={columns} dataSource={attendanceData} rowKey="id" />
      {loading && (
        <Spin
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
      {error && (
        <div style={{ color: 'red', marginTop: 10 }}>
          Error: {error}
        </div>
      )}
    </div>
  );
};

export default EmployeeAttendanceReport;
