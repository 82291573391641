import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Row, Col, Modal, message } from 'antd';
import moment from 'moment';
import Clock from './Clock'; // Import the Clock component

const Empdashboard = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userFirstName, setUserFirstName] = useState('');
  const [userId, setUserId] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startButtonDisabled, setStartButtonDisabled] = useState(false);
  const [endButtonDisabled, setEndButtonDisabled] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [showClock, setShowClock] = useState(false); // State to show/hide the clock

  useEffect(() => {
    const firstName = sessionStorage.getItem('userFirstName');
    const id = sessionStorage.getItem('userId');
    setUserFirstName(firstName);
    setUserId(id);

    const auth = sessionStorage.getItem('authenticated');
    setAuthenticated(auth === 'true');

    // Check if attendance data exists for today
    checkAttendanceForToday(id);

    // Get stored times and flags
    const today = moment().format('YYYY-MM-DD');
    const storedStartTime = sessionStorage.getItem(`startTime_${id}_${today}`);
    const storedEndTime = sessionStorage.getItem(`endTime_${id}_${today}`);
    const attendanceRecorded = sessionStorage.getItem(`attendanceRecorded_${id}_${today}`);

    if (storedStartTime) {
      setStartTime(storedStartTime);
      setStartButtonDisabled(true);
      if (!storedEndTime) {
        setShowClock(true); // Show the clock if start time is set and no end time
      }
    }

    if (storedEndTime) {
      setEndTime(storedEndTime);
      setEndButtonDisabled(true);
      setShowClock(false); // Hide the clock if end time is set
    }

    if (attendanceRecorded) {
      setStartButtonDisabled(true);
      setEndButtonDisabled(true);
      setShowClock(false); // Hide the clock if attendance is recorded
    }
  }, []);

  const checkAttendanceForToday = async (employeeId) => {
    try {
      const response = await fetch('https://testing.vaptix.com/getEmpAttendanceById.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ employeeId }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch attendance data');
      }

      const data = await response.json();
      const today = moment().format('YYYY-MM-DD');
      const attendanceForToday = data.data.find(record => record.date === today);

      if (attendanceForToday) {
        setStartButtonDisabled(true);
        setStartTime(attendanceForToday.login_time);
        setEndTime(attendanceForToday.logout_time || '');
        setEndButtonDisabled(attendanceForToday.logout_time !== null);
        if (!attendanceForToday.logout_time) {
          setShowClock(true); // Show the clock if no end time is recorded
        }
      } else {
        setStartButtonDisabled(false);
        setStartTime('');
        setEndTime('');
        setEndButtonDisabled(false);
        setShowClock(false);
      }
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    }
  };

  const handleStartWork = async () => {
    const currentTime = moment().format('HH:mm:ss');
    setStartTime(currentTime);
    sessionStorage.setItem(`startTime_${userId}_${moment().format('YYYY-MM-DD')}`, currentTime);
    sessionStorage.setItem(`startAlreadyRecorded_${userId}_${moment().format('YYYY-MM-DD')}`, true);
    setStartButtonDisabled(true);
    setShowClock(true); // Show the clock

    try {
      const response = await fetch('https://testing.vaptix.com/employeeAttendance.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          employeeId: userId,
          date: moment().format('YYYY-MM-DD'),
          loginTime: currentTime,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update login time');
      }

      const data = await response.json();
      console.log('Login time updated:', data);
      message.success('Login time updated successfully');
    } catch (error) {
      console.error('Error updating login time:', error);
      message.error('Failed to update login time. Please try again.');
    }
  };

  const handleEndWork = () => {
    const currentTime = moment().format('HH:mm:ss');
    setEndTime(currentTime);
    setConfirmVisible(true);
  };

  const handleCancelSubmit = () => {
    setEndTime('');
    setConfirmVisible(false);
    setShowClock(true); // Keep the clock visible if not submitted
  };

  const handleConfirmSubmit = async () => {
    setConfirmVisible(false);
    setLoading(true);
    try {
      const response = await fetch('https://testing.vaptix.com/updateAttendance.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          employeeId: userId,
          date: moment().format('YYYY-MM-DD'),
          loginTime: startTime,
          logoutTime: endTime,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update attendance');
      }

      const data = await response.json();
      console.log('Attendance updated:', data);
      message.success('Attendance updated successfully');

      // Disable buttons for the rest of the day after successful submission
      setStartButtonDisabled(true);
      setEndButtonDisabled(true);

      // Set attendanceRecorded flag in session storage with today's date for the current user
      sessionStorage.setItem(`attendanceRecorded_${userId}_${moment().format('YYYY-MM-DD')}`, true);
      sessionStorage.setItem(`endTime_${userId}_${moment().format('YYYY-MM-DD')}`, endTime);

      // Hide the clock
      setShowClock(false);
    } catch (error) {
      console.error('Error updating attendance:', error);
      message.error('Failed to update attendance. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const formatDuration = (start, end) => {
    if (!start || !end) return '00:00:00'; // Return zero duration if either start or end is missing

    const duration = moment.duration(moment(end, 'HH:mm:ss').diff(moment(start, 'HH:mm:ss')));
    const hours = String(duration.hours()).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');
    const seconds = String(duration.seconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  // Calculate duration to determine if the end button should be disabled
  const duration = formatDuration(startTime, endTime);
  const shouldDisableEndButton = duration !== '00:00:00';

  return (
    <div>
      <Card
        title={
          <Row>
            <Col xs={8} md={8}>
              <p style={{ textAlign: 'left' }}>Today's Date: {moment().format('YYYY-MM-DD')}</p>
            </Col>
            <Col xs={8} md={8}>
              <span>Duration: {formatDuration(startTime, endTime)}</span>
            </Col>
          </Row>
        }
        style={{ width: '100%' }}
      >
        <Form form={form}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>
              <Form.Item>
                <Button type="primary" onClick={handleStartWork} disabled={!authenticated || startButtonDisabled}>
                  Start
                </Button>
                {startTime && (
                  <span style={{ marginLeft: '8px' }}>Work started at: {startTime}</span>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item>
                <Button type="primary" onClick={handleEndWork} disabled={!startTime || shouldDisableEndButton}>
                  End
                </Button>
                {endTime && (
                  <span style={{ marginLeft: '8px' }}>Work ended at: {endTime}</span>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item>
                {startTime && endTime && (
                  <span>Duration: {formatDuration(startTime, endTime)}</span>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {showClock && !shouldDisableEndButton && <Clock startTime={startTime} />}
      </Card>

      <Modal
        title="Confirm Submit"
        visible={confirmVisible}
        onCancel={handleCancelSubmit}
        footer={[
          <Button key="cancel" onClick={handleCancelSubmit}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleConfirmSubmit}>
            Submit
          </Button>,
        ]}
      >
        <p>Do you want to submit the recorded time?</p>
      </Modal>
    </div>
  );
};

export default Empdashboard;
