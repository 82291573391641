import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Form, Modal, message } from 'antd';
import axios from 'axios';

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

  // Function to fetch profile data
  const fetchProfileData = async () => {
    try {
      const userId = sessionStorage.getItem('userId');
      if (!userId) {
        message.error('User ID not found in session.');
        return;
      }

      const response = await axios.post('https://testing.vaptix.com/get_user_profile.php', {
        userId: userId,
      });

      if (response.data.status === 'success') {
        setProfileData(response.data.data);
        form.setFieldsValue({
          firstName: response.data.data.firstname,
          lastName: response.data.data.lastname,
          contactNo: response.data.data.phone_no,
          email: response.data.data.email,
        });
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
      message.error('Failed to fetch profile data.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch profile data when component mounts
  useEffect(() => {
    fetchProfileData();
  }, [form]);

  const showUpdateModal = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      const userId = sessionStorage.getItem('userId');
      const response = await axios.post('https://testing.vaptix.com/update_user_profile.php', {
        userId: userId,
        ...values,
      });

      if (response.data.status === 'success') {
        message.success('Profile updated successfully');
        setProfileData(values); // Update the local state with new values
        setIsModalVisible(false); // Close the modal
        // Refresh the profile data
        fetchProfileData();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      message.error('Failed to update profile.');
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const showUpdatePasswordModal = () => {
    setIsPasswordModalVisible(true);
  };

  const handlePasswordModalOk = async () => {
    try {
      const values = await form.validateFields(['oldPassword', 'newPassword']);
      const userId = sessionStorage.getItem('userId');
      const response = await axios.post('https://testing.vaptix.com/update_user_password.php', {
        userId: userId,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });

      if (response.data.status === 'success') {
        message.success('Password updated successfully');
        setIsPasswordModalVisible(false); // Close the modal
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error('Error updating password:', error);
      message.error('Failed to update password.');
    }
  };

  const handlePasswordModalCancel = () => {
    setIsPasswordModalVisible(false);
  };

  return (
    <>
      <Card title="Profile" loading={loading}>
        {profileData ? (
          <>
            <div style={{ marginBottom: '20px' }}>
              <p><strong>First Name:</strong> {profileData.firstname}</p>
              <p><strong>Last Name:</strong> {profileData.lastname}</p>
              <p><strong>Contact No:</strong> {profileData.phone_no}</p>
              <p><strong>Email:</strong> {profileData.email}</p>
            </div>
            <Button type="primary" onClick={showUpdateModal}>
              Update Profile
            </Button>
            {/* <Button type="dashed" onClick={showUpdatePasswordModal} style={{ marginLeft: '10px' }}>
              Update Password
            </Button> */}

            <Modal
              title="Update Profile"
              visible={isModalVisible}
              onOk={handleModalOk}
              onCancel={handleModalCancel}
              okText="Update"
              cancelText="Cancel"
            >
              <Form
                form={form}
                layout="vertical"
                initialValues={{
                  firstName: profileData.firstname,
                  lastName: profileData.lastname,
                  contactNo: profileData.phone_no,
                  email: profileData.email,
                }}
              >
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[{ required: true, message: 'Please input the first name!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[{ required: true, message: 'Please input the last name!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="contactNo"
                  label="Contact No"
                  rules={[{ required: true, message: 'Please input the contact number!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email ID"
                  rules={[{ required: true, message: 'Please input the email ID!', type: 'email' }]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Modal>

            <Modal
              title="Update Password"
              visible={isPasswordModalVisible}
              onOk={handlePasswordModalOk}
              onCancel={handlePasswordModalCancel}
              okText="Update"
              cancelText="Cancel"
            >
              <Form
                form={form}
                layout="vertical"
                initialValues={{
                  oldPassword: '',
                  newPassword: '',
                }}
              >
                <Form.Item
                  name="oldPassword"
                  label="Old Password"
                  rules={[{ required: true, message: 'Please input your old password!' }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  label="New Password"
                  rules={[{ required: true, message: 'Please input your new password!' }]}
                >
                  <Input.Password />
                </Form.Item>
              </Form>
            </Modal>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </Card>
    </>
  );
};

export default Profile;
