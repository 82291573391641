import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Space, Modal, Switch, message, Spin, Form } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const EmployeeList = () => {
  const [searchText, setSearchText] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    fetchEmployeeList();
  }, []);

  const fetchEmployeeList = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://testing.vaptix.com/view_users.php');
      if (!response.ok) {
        throw new Error('Failed to fetch employee list');
      }
      const data = await response.json();
      if (data.status === 'success') {
        setEmployees(data.data);
      } else {
        throw new Error(data.message || 'Failed to fetch employee list');
      }
    } catch (error) {
      console.error('Error fetching employee list:', error);
      setError('Failed to fetch employee list. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredEmployees = employees.filter((employee) =>
    `${employee.firstname} ${employee.lastname}`.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleEdit = (employee) => {
    setSelectedEmployee(employee);
    editForm.setFieldsValue({
      id: employee.employee_id,
      username: employee.firstname,
      email: employee.email,
      phone_no: employee.phone_no,
    });
    setIsEditModalVisible(true);
  };

  const handleDelete = async (employeeId) => {
    try {
      setLoading(true);
      const response = await fetch('https://testing.vaptix.com/delete_user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ employee_id: employeeId }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete employee');
      }

      const data = await response.json();
      if (data.status === 'success') {
        message.success('Employee deleted successfully');
        fetchEmployeeList();
      } else {
        throw new Error(data.message || 'Failed to delete employee');
      }
    } catch (error) {
      console.error('Error deleting employee:', error);
      message.error('Failed to delete employee. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddEmployee = async (values) => {
    try {
      setLoading(true);
      const response = await fetch('https://testing.vaptix.com/create_user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error('Failed to add employee');
      }

      const data = await response.json();
      if (data.status === 'success') {
        message.success('Employee added successfully');
        fetchEmployeeList();
        setIsModalVisible(false);
        form.resetFields();
      } else {
        throw new Error(data.message || 'Failed to add employee');
      }
    } catch (error) {
      console.error('Error adding employee:', error);
      message.error('Failed to add employee. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditEmployee = async (values) => {
    try {
      setLoading(true);
      const response = await fetch('https://testing.vaptix.com/edit_user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error('Failed to edit employee');
      }

      const data = await response.json();
      if (data.status === 'success') {
        message.success('Employee updated successfully');
        fetchEmployeeList();
        setIsEditModalVisible(false);
        editForm.resetFields();
      } else {
        throw new Error(data.message || 'Failed to edit employee');
      }
    } catch (error) {
      console.error('Error editing employee:', error);
      message.error('Failed to edit employee. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusToggle = async (checked, employeeId, firstname) => {
    const status = checked ? 'active' : 'inactive';
    try {
      setLoading(true);
      const response = await fetch('https://testing.vaptix.com/update_user_status.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ employee_id: employeeId, status }),
      });

      if (!response.ok) {
        throw new Error('Failed to update employee status');
      }

      const data = await response.json();
      if (data.status !== 'success') {
        throw new Error(data.message || 'Failed to update employee status');
      }
      message.success(`${employeeId} -> ${firstname} status updated to ${status}`);
      fetchEmployeeList();
    } catch (error) {
      console.error('Error updating employee status:', error);
      message.error('Failed to update employee status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  const validateContactNo = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Please input the contact number!'));
    }
    if (!/^\d{10}$/.test(value)) {
      return Promise.reject(new Error('Contact number must be exactly 10 digits long and contain only numbers.'));
    }
    return Promise.resolve();
  };
  const toggleEditModal = () => {
    setIsEditModalVisible(!isEditModalVisible);
  };

  const columns = [
    {
      title: 'Emp ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
    },
    {
      title: 'First Name',
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: 'Contact No',
      dataIndex: 'phone_no',
      key: 'phone_no',
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Switch
          checked={status === 'active'}
          onChange={(checked) => handleStatusToggle(checked, record.employee_id, record.firstname)}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEdit(record)} icon={<EditOutlined />} />
          <Button onClick={() => handleDelete(record.employee_id)} icon={<DeleteOutlined />} />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={loading}>
        {error && <div>{error}</div>}
        <Input.Search
          placeholder="Search employees..."
          onChange={handleSearch}
          style={{ marginBottom: 16, width: 300 }}
        />
        <Button
          type="primary"
          onClick={toggleModal}
          icon={<PlusOutlined />}
          style={{ marginBottom: 16 }}
        >
          Add Employee
        </Button>
        <Table
          columns={columns}
          dataSource={filteredEmployees}
          rowKey="employee_id"
        />
      </Spin>
      <Modal
        title="Add Employee"
        visible={isModalVisible}
        onCancel={toggleModal}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddEmployee}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'Please input the first name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Please input the last name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contactNo"
            label="Contact No"
            rules={[{ required: true, validator: validateContactNo }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input the password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Employee
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Employee"
        visible={isEditModalVisible}
        onCancel={toggleEditModal}
        footer={null}
      >
        <Form
          form={editForm}
          layout="vertical"
          onFinish={handleEditEmployee}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="username"
            label="First Name"
            rules={[{ required: true, message: 'Please input the first name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone_no"
            label="Phone No"
            rules={[{ required: true, validator: validateContactNo }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Employee
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EmployeeList;
