import React, { useState, useEffect } from 'react';
import { Table, Space, DatePicker, Input, Button, Spin,Row,Col, message } from 'antd';
import axios from 'axios';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Search } = Input;

const AttendanceSheet = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [employeeIdFilter, setEmployeeIdFilter] = useState('');
  const [monthFilter, setMonthFilter] = useState('');
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch attendance data
  const fetchAttendanceData = async () => {
    try {
      setLoading(true);
      const timestamp = new Date().getTime(); // Unique timestamp to bust cache
      const response = await axios.get(`https://testing.vaptix.com/getAllEmpAttendance.php?timestamp=${timestamp}`);
      
      if (response.data.status === 'success') {
        setAttendanceData(response.data.data);
      } else {
        throw new Error(response.data.message || 'Failed to fetch attendance data');
      }
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      setError('Failed to fetch attendance data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch user data
  const fetchUserData = async () => {
    try {
      const response = await axios.get('https://testing.vaptix.com/view_users.php');
      
      if (response.data.status === 'success') {
        const userDataMap = {};
        response.data.data.forEach(user => {
          userDataMap[user.employee_id] = `${user.firstname} ${user.lastname}`;
        });
        setUserData(userDataMap);
      } else {
        console.error('Failed to fetch user data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // Effect to fetch data on initial component load
  useEffect(() => {
    fetchAttendanceData();
    fetchUserData();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  // Effect to refilter data when filters change
  useEffect(() => {
    const filtered = attendanceData.filter(record => {
      let passesDateRange = true;
      let passesEmployeeId = true;
      let passesMonth = true;

      // Check date range filter
      if (dateRange && dateRange.length === 2) {
        const startDate = moment(dateRange[0]);
        const endDate = moment(dateRange[1]);
        const recordDate = moment(record.date, 'YYYY-MM-DD');
        passesDateRange = recordDate.isBetween(startDate, endDate, 'day', '[]');
      }

      // Check employee ID filter
      if (employeeIdFilter !== '') {
        passesEmployeeId = record.employee_id.toLowerCase().includes(employeeIdFilter.toLowerCase());
      }

      // Check month filter
      if (monthFilter !== '') {
        const recordDate = moment(record.date, 'YYYY-MM-DD');
        passesMonth = recordDate.format('YYYY-MM') === monthFilter;
      }

      return passesDateRange && passesEmployeeId && passesMonth;
    });

    setFilteredData(filtered);
  }, [attendanceData, dateRange, employeeIdFilter, monthFilter]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates || []);
  };

  const handleEmployeeIdChange = (e) => {
    setEmployeeIdFilter(e.target.value);
  };

  const handleMonthChange = (value) => {
    setMonthFilter(value);
  };

  const handleRefresh = () => {
    // Clear existing data
    setAttendanceData([]);
    setFilteredData([]);
    setUserData({});

    // Fetch updated data
    fetchAttendanceData();
    fetchUserData();
  };

  const columns = [
    { title: 'Employee ID', dataIndex: 'employee_id', key: 'employee_id' },
    { title: 'Employee Name', dataIndex: 'employee_id', key: 'employeeName',
      render: (employeeId) => userData[employeeId] || 'Unknown' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Login Time', dataIndex: 'login_time', key: 'login_time' },
    { title: 'Logout Time', dataIndex: 'logout_time', key: 'logout_time' },
    {
      title: 'Duration',
      render: (text, record) => (
        <span>{`${record.durationH}:${record.durationM}:${record.durationS}`}</span>
      ),
      key: 'duration',
    },
  ];

  return (
    <div>
      <h2>Attendance Sheet</h2>
      <div style={{ marginBottom: 16 }}>
      <Row gutter={[16, 16]}>            
            <Col xs={24} md={8}>
            <RangePicker value={dateRange} onChange={handleDateRangeChange} />
          </Col>
          <Col xs={24} md={6}>
          <DatePicker.MonthPicker value={monthFilter ? moment(monthFilter) : null} onChange={(date, dateString) => handleMonthChange(dateString)} placeholder="Select month" />
          </Col>
            <Col xs={24} md={6}>
            <Search placeholder="Search by Employee ID" value={employeeIdFilter} onChange={handleEmployeeIdChange} style={{ width: 200 }} />
        
          </Col>
          
          <Col xs={24} md={4}>
          <Button type="primary" onClick={handleRefresh}>Refresh Data</Button></Col>
        </Row>
      </div>
      <div style={{ position: 'relative' }}>
        <Table columns={columns} dataSource={filteredData} rowKey="id" />
        {loading && (
          <Spin
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      </div>
      {error && (
        <div style={{ color: 'red', marginTop: 10 }}>
          Error: {error}
        </div>
      )}
    </div>
  );
};

export default AttendanceSheet;
