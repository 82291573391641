import React, { useState, useEffect } from 'react';
import { Layout, Menu, Dropdown, Button, message } from 'antd';
import { UserOutlined, ScheduleOutlined, ProfileOutlined } from '@ant-design/icons';
import Empdashboard from '../components/Employee/Dashboard';
import LogHours from '../components/Employee/LogHours';
import MyPerformance from '../components/Employee/MyPerformance';
import Profile from '../components/Employee/Profile';

const { Sider, Content } = Layout;

const EmployeeDashboard = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard');
  const [showDropdown, setShowDropdown] = useState(false);
  const [userFirstName, setUserFirstName] = useState('');

  useEffect(() => {
    const firstName = sessionStorage.getItem('userFirstName');
    setUserFirstName(firstName);
  }, []);

  const handleMenuClick = ({ key }) => {
    if (key === 'logout') {
      sessionStorage.clear();
      message.success('You have been logged out.');
      window.location.reload(); // Reload the page to clear state
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="logout">Logout</Menu.Item>
    </Menu>
  );

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'loghours':
        return <LogHours />;
      case 'myperformance':
        return <MyPerformance />;
      case 'profile':
        return <Profile />;
      default:
        return <Empdashboard />;
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={240} className="site-layout-background">
        <Menu
          mode="inline"
          defaultSelectedKeys={['dashboard']}
          style={{ height: '100%', borderRight: 0 }}
          onClick={(e) => setSelectedMenuItem(e.key)}
        >
          <Menu.Item key="dashboard" icon={<UserOutlined />}>
            Dashboard
          </Menu.Item>
          <Menu.Item key="loghours" icon={<ScheduleOutlined />}>
            Log Hours
          </Menu.Item>
          <Menu.Item key="myperformance" icon={<ProfileOutlined />}>
            My Performance
          </Menu.Item>
          <Menu.Item key="profile" icon={<UserOutlined />}>
            Profile
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            position: 'relative'
          }}
        >
          <div style={{ position: 'absolute', right: 24, top: 20 }}>
            <Dropdown overlay={menu} trigger={['click']} visible={showDropdown} onVisibleChange={setShowDropdown}>
              <Button danger type="primary" onClick={() => setShowDropdown(!showDropdown)} >
                {userFirstName} <UserOutlined />
              </Button>
            </Dropdown>
          </div>
          <div style={{marginTop:'40px'}}>
          {renderContent()}
          </div>
          
        </Content>
      </Layout>
    </Layout>
  );
};

export default EmployeeDashboard;
